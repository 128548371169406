import Axios from 'axios';

import {
	LOGS_LOADING_TRUE,
	LOGS_LOADING_FALSE,
	LOGS_GET,
	LOGS_GET_ERROR
} from './types';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const logs_loading_true = () => dispatch => {
	dispatch({
		type: LOGS_LOADING_TRUE
	})
}

export const logs_loading_false = () => dispatch => {
	dispatch({
		type: LOGS_LOADING_FALSE
	})
}

export const parseLogsToJSON = (resData) => {
	resData.forEach((element, idx) => {
		resData[idx] = JSON.parse(element);
	});
	return resData;
}

export const get_logs = (jobsType, instance_id, filters) => dispatch => {
	// console.log(filters)
	dispatch(logs_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/${jobsType}/${instance_id}/jobs/logs?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		if (res.data.length > 0) {
			dispatch({
				type: LOGS_GET,
				payload: parseLogsToJSON(res.data)
			});
		}
		else {
			dispatch({
				type: LOGS_GET_ERROR,
				payload: { type: 'logs', msg: 'No logs' }
			});
		}
		dispatch(logs_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOGS_GET_ERROR,
			payload: { type: 'logs', msg: err.message }
		});
		dispatch(logs_loading_false());
	})
}
