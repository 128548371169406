export const ERRORS_GET = 'ERRORS_GET';
export const ERRORS_CLEAR = 'ERRORS_CLEAR';

export const SUCCESS_GET = 'SUCCESS_GET';
export const SUCCESS_CLEAR = 'SUCCESS_CLEAR';

// Alert
export const ALERT_SET = 'ALERT_SET';
export const ALERT_REMOVE = 'ALERT_REMOVE';

// User
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_INIT = 'USER_INIT';
export const USER_INIT_CORRECT = 'USER_INIT_CORRECT';
export const USER_INIT_ERROR = 'USER_INIT_ERROR';

// Organization
export const ORGANIZATIONS_GET = 'ORGANIZATIONS_GET';
export const ORGANIZATIONS_ERROR = 'ORGANIZATIONS_ERROR';
export const ORG_INFO_GET = 'ORG_INFO_GET';
export const ORG_INFO_ERROR = 'ORG_INFO_ERROR';

export const WORKSPACES_GET = 'WORKSPACES_GET';
export const WORKSPACES_ERROR = 'WORKSPACES_ERROR';

export const MODULES_GET = 'MODULES_GET';
export const MODULES_ERROR = 'MODULES_ERROR';

export const CYCLES_GET = 'CYCLES_GET';
export const CYCLES_ERROR = 'CYCLES_ERROR';

// Load Services
export const SERVICES_LOADING_TRUE = 'SERVICES_LOADING_TRUE';
export const SERVICES_LOADING_FALSE = 'SERVICES_LOADING_FALSE';

export const LOAD_SERVICES_INFO_GET = 'LOAD_SERVICES_INFO_GET';
export const LOAD_SERVICES_INFO_ERROR = 'LOAD_SERVICES_INFO_ERROR';

export const SERVICE_STATE_GET = 'SERVICE_STATE_GET';
export const SERVICE_STATE_ERROR = 'SERVICE_STATE_ERROR';

// Load
export const LOAD_LOADING_TRUE = 'LOAD_LOADING_TRUE';
export const LOAD_LOADING_FALSE = 'LOAD_LOADING_FALSE';

export const LOAD_INFO_GET = 'LOAD_INFO_GET';
export const LOAD_INFO_ERROR = 'LOAD_INFO_ERROR';

export const LOAD_DATA_GET = 'LOAD_DATA_GET';
export const LOAD_DATA_ERROR = 'LOAD_DATA_ERROR';
export const LOAD_DATA_INSTANCES_GET = 'LOAD_DATA_INSTANCES_GET';
export const LOAD_DATA_INSTANCES_ERROR = 'LOAD_DATA_INSTANCES_ERROR';

// Instances
export const INSTANCE_OPTIONS_GET = 'INSTANCE_OPTIONS_GET';
export const INSTANCE_OPTIONS_ERROR = 'INSTANCE_OPTIONS_ERROR';

export const INSTANCE_MACHINES_GET = 'INSTANCE_MACHINES_GET';
export const INSTANCE_MACHINES_ERROR = 'INSTANCE_MACHINES_ERROR';

// Workers
export const WORKERS_LOADING_TRUE = 'WORKERS_LOADING_TRUE';
export const WORKERS_LOADING_FALSE = 'WORKERS_LOADING_FALSE';

export const ALL_WORKER_RESOURCES_GET = 'ALL_WORKER_RESOURCES_GET';
export const ALL_WORKER_RESOURCES_ERROR = 'ALL_WORKER_RESOURCES_ERROR';

export const WORKER_INFO_GET = 'WORKER_INFO_GET';
export const WORKER_INFO_ERROR = 'WORKER_INFO_ERROR';

export const WORKER_INSTANCES_LOADING_TRUE = 'WORKER_INSTANCES_LOADING_TRUE';
export const WORKER_INSTANCES_LOADING_FALSE = 'WORKER_INSTANCES_LOADING_FALSE';
export const WORKER_INSTANCES_GET = 'WORKER_INSTANCES_GET';
export const WORKER_INSTANCES_ERROR = 'WORKER_INSTANCES_ERROR';

export const WORKER_START = 'WORKER_START';
export const WORKER_START_ERROR = 'WORKER_START_ERROR';
export const WORKER_STOP = 'WORKER_STOP';
export const WORKER_STOP_ERROR = 'WORKER_STOP_ERROR';
export const WORKER_END = 'WORKER_END';
export const WORKER_END_ERROR = 'WORKER_END_ERROR';

export const WORKER_EVENTS_LOADING_TRUE = 'WORKER_EVENTS_LOADING_TRUE';
export const WORKER_EVENTS_GET = 'WORKER_EVENTS_GET';
export const WORKER_EVENTS_ERROR = 'WORKER_EVENTS_ERROR';

export const WORKER_JOB_CREATE_LOADING_TRUE = 'WORKER_JOB_CREATE_LOADING_TRUE';
export const WORKER_JOB_CREATE_SUCCESS = 'WORKER_JOB_CREATE_SUCCESS';
export const WORKER_JOB_CREATE_ERROR = 'WORKER_JOB_CREATE_ERROR';

export const WORKER_COMMANDS_LOADING = 'WORKER_COMMANDS_LOADING';
export const WORKER_COMMANDS_GET = 'WORKER_COMMANDS_GET';
export const WORKER_COMMANDS_ERROR = 'WORKER_COMMANDS_ERROR';

// Worker Instance
export const WORKER_INSTANCE_LOADING_TRUE = 'WORKER_INSTANCE_LOADING_TRUE';
export const WORKER_INSTANCE_LOADING_FALSE = 'WORKER_INSTANCE_LOADING_FALSE';

export const WORKER_INSTANCE_INFO_GET = 'WORKER_INSTANCE_INFO_GET';
export const WORKER_INSTANCE_INFO_ERROR = 'WORKER_INSTANCE_INFO_ERROR';

export const WORKER_INSTANCE_DATA_GET = 'WORKER_INSTANCE_DATA_GET';
export const WORKER_INSTANCE_DATA_ERROR = 'WORKER_INSTANCE_DATA_ERROR';
export const WORKER_INSTANCE_DATA_HISTORY_GET = 'WORKER_INSTANCE_DATA_HISTORY_GET';
export const WORKER_INSTANCE_DATA_HISTORY_ERROR = 'WORKER_INSTANCE_DATA_HISTORY_ERROR';

export const WORKER_INSTANCE_CONFIG_GET = 'WORKER_INSTANCE_CONFIG_GET';
export const WORKER_INSTANCE_CONFIG_ERROR = 'WORKER_INSTANCE_CONFIG_ERROR';

export const WORKER_INSTANCE_START_ERROR = 'WORKER_INSTANCE_START_ERROR';
export const WORKER_INSTANCE_STOP_ERROR = 'WORKER_INSTANCE_STOP_ERROR';
export const WORKER_INSTANCE_END_ERROR = 'WORKER_INSTANCE_END_ERROR';
export const WORKER_INSTANCE_CLEAN_ERROR = 'WORKER_INSTANCE_CLEAN_ERROR';

export const WORKER_INSTANCE_STATUS_HISTORY_GET = 'WORKER_INSTANCE_STATUS_HISTORY_GET';
export const WORKER_INSTANCE_STATUS_HISTORY_ERROR = 'WORKER_INSTANCE_STATUS_HISTORY_ERROR';

export const WORKER_INSTANCE_COMMANDS_LOADING = 'WORKER_INSTANCE_COMMANDS_LOADING';
export const WORKER_INSTANCE_COMMANDS_GET = 'WORKER_INSTANCE_COMMANDS_GET';
export const WORKER_INSTANCE_COMMANDS_ERROR = 'WORKER_INSTANCE_COMMANDS_ERROR';

// Jobs
export const JOBS_LOADING_TRUE = 'JOBS_LOADING_TRUE';
export const JOBS_LOADING_FALSE = 'JOBS_LOADING_FALSE';

export const JOB_INFO_LOADING_TRUE = 'JOB_INFO_LOADING_TRUE';
export const JOB_INFO_LOADING_FALSE = 'JOB_INFO_LOADING_FALSE';
export const JOB_INFO_GET = 'JOB_INFO_GET';
export const JOB_INFO_GET_ERROR = 'JOB_INFO_GET_ERROR';

export const JOBS_WAITING_LOADING_TRUE = 'JOBS_WAITING_LOADING_TRUE';
export const JOBS_WAITING_LOADING_FALSE = 'JOBS_WAITING_LOADING_FALSE';
export const JOBS_WAITING_GET = 'JOBS_WAITING_GET';
export const JOBS_WAITING_GET_ERROR = 'JOBS_WAITING_GET_ERROR';

export const JOB_CURRENT_GET = 'JOB_CURRENT_GET';
export const JOB_CURRENT_GET_ERROR = 'JOB_CURRENT_GET_ERROR';

export const JOB_LAST_GET = 'JOB_LAST_GET';
export const JOB_LAST_GET_ERROR = 'JOB_LAST_GET_ERROR';

export const JOBS_HISTORY_LOADING_TRUE = 'JOBS_HISTORY_LOADING_TRUE';
export const JOBS_HISTORY_LOADING_FALSE = 'JOBS_HISTORY_LOADING_FALSE';
export const JOBS_HISTORY_GET = 'JOBS_HISTORY_GET';
export const JOBS_HISTORY_GET_ERROR = 'JOBS_HISTORY_GET_ERROR';

export const JOBS_FAILED_LOADING_TRUE = 'JOBS_FAILED_LOADING_TRUE';
export const JOBS_FAILED_LOADING_FALSE = 'JOBS_FAILED_LOADING_FALSE';
export const JOBS_FAILED_GET = 'JOBS_FAILED_GET';
export const JOBS_FAILED_GET_ERROR = 'JOBS_FAILED_GET_ERROR';

export const JOB_REMOVED = 'JOB_REMOVED';

export const JOB_WORKER_INSTANCE_SEARCH = 'JOB_WORKER_INSTANCE_SEARCH';
export const JOB_WORKER_INSTANCE_SEARCH_ERROR = 'JOB_WORKER_INSTANCE_SEARCH_ERROR';

export const SEARCH_JOB_LOADING_TRUE = 'SEARCH_JOB_LOADING_TRUE';
export const SEARCH_JOB = 'SEARCH_JOB';
export const SEARCH_JOB_ERROR = 'SEARCH_JOB_ERROR';

// Logs
export const LOGS_LOADING_TRUE = 'LOGS_LOADING_TRUE';
export const LOGS_LOADING_FALSE = 'LOGS_LOADING_FALSE';

export const LOGS_GET = 'LOGS_GET';
export const LOGS_GET_ERROR = 'LOGS_GET_ERROR';

// Messages
export const MESSAGES_LOADING_TRUE = 'MESSAGES_LOADING_TRUE';
export const MESSAGES_LOADING_FALSE = 'MESSAGES_LOADING_FALSE';

export const ALL_MESSAGES_GET = 'ALL_MESSAGES_GET';
export const ALL_MESSAGES_GET_ERROR = 'ALL_MESSAGES_GET_ERROR';

export const UPDATE_MESSAGE_LOADING_TRUE = 'UPDATE_MESSAGE_LOADING_TRUE';
export const UPDATE_MESSAGE_LOADING_FALSE = 'UPDATE_MESSAGE_LOADING_FALSE';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';

// Helpers
export const HELPERS_LOADING = 'HELPERS_LOADING';

export const ALL_HELPERS_GET = 'ALL_HELPERS_GET';
export const ALL_HELPERS_ERROR = 'ALL_HELPERS_ERROR';

export const HELPER_INFO_GET = 'HELPER_INFO_GET';
export const HELPER_INFO_ERROR = 'HELPER_INFO_ERROR';

export const HELPER_INSTANCES_LOADING = 'HELPER_INSTANCES_LOADING';
export const HELPER_INSTANCES_GET = 'HELPER_INSTANCES_GET';
export const HELPER_INSTANCES_ERROR = 'HELPER_INSTANCES_ERROR';

export const HELPER_START = 'HELPER_START';
export const HELPER_START_ERROR = 'HELPER_START_ERROR';
export const HELPER_STOP = 'HELPER_STOP';
export const HELPER_STOP_ERROR = 'HELPER_STOP_ERROR';
export const HELPER_END = 'HELPER_END';
export const HELPER_END_ERROR = 'HELPER_END_ERROR';

export const HELPER_COMMANDS_LOADING = 'HELPER_COMMANDS_LOADING';
export const HELPER_COMMANDS_GET = 'HELPER_COMMANDS_GET';
export const HELPER_COMMANDS_ERROR = 'HELPER_COMMANDS_ERROR';

// Helper Instance
export const HELPER_INSTANCE_INFO_LOADING = 'HELPER_INSTANCE_INFO_LOADING';
export const HELPER_INSTANCE_INFO_GET = 'HELPER_INSTANCE_INFO_GET';
export const HELPER_INSTANCE_INFO_ERROR = 'HELPER_INSTANCE_INFO_ERROR';

export const HELPER_INSTANCE_DATA_GET = 'HELPER_INSTANCE_DATA_GET';
export const HELPER_INSTANCE_DATA_ERROR = 'HELPER_INSTANCE_DATA_ERROR';
export const HELPER_INSTANCE_DATA_HISTORY_GET = 'HELPER_INSTANCE_DATA_HISTORY_GET';
export const HELPER_INSTANCE_DATA_HISTORY_ERROR = 'HELPER_INSTANCE_DATA_HISTORY_ERROR';

export const HELPER_INSTANCE_CONFIG_GET = 'HELPER_INSTANCE_CONFIG_GET';
export const HELPER_INSTANCE_CONFIG_ERROR = 'HELPER_INSTANCE_CONFIG_ERROR';

export const HELPER_INSTANCE_START_ERROR = 'HELPER_INSTANCE_START_ERROR';
export const HELPER_INSTANCE_STOP_ERROR = 'HELPER_INSTANCE_STOP_ERROR';
export const HELPER_INSTANCE_END_ERROR = 'HELPER_INSTANCE_END_ERROR';
export const HELPER_INSTANCE_CLEAN_ERROR = 'HELPER_INSTANCE_CLEAN_ERROR';

export const HELPER_INSTANCE_STATUS_HISTORY_GET = 'HELPER_INSTANCE_STATUS_HISTORY_GET';
export const HELPER_INSTANCE_STATUS_HISTORY_ERROR = 'HELPER_INSTANCE_STATUS_HISTORY_ERROR';

export const HELPER_INSTANCE_COMMANDS_LOADING = 'HELPER_INSTANCE_COMMANDS_LOADING';
export const HELPER_INSTANCE_COMMANDS_GET = 'HELPER_INSTANCE_COMMANDS_GET';
export const HELPER_INSTANCE_COMMANDS_ERROR = 'HELPER_INSTANCE_COMMANDS_ERROR';

// Models
export const MODELS_LOADING = 'MODELS_LOADING';

export const MODEL_CREATE_LOADING = 'MODEL_CREATE_LOADING';
export const MODEL_CREATE_SUCCESS = 'MODEL_CREATE_SUCCESS';
export const MODEL_CREATE_ERROR = 'MODEL_CREATE_ERROR';

export const ALL_MODELS_GET = 'ALL_MODELS_GET';
export const ALL_MODELS_ERROR = 'ALL_MODELS_ERROR';

export const MODEL_INFO_GET = 'MODEL_INFO_GET';
export const MODEL_INFO_ERROR = 'MODEL_INFO_ERROR';

export const MODEL_UPDATE_ERROR = 'MODEL_UPDATE_ERROR';

export const MODEL_STATUS_HISTORY_LOADING = 'MODEL_STATUS_HISTORY_LOADING';
export const MODEL_STATUS_HISTORY_GET = 'MODEL_STATUS_HISTORY_GET';
export const MODEL_STATUS_HISTORY_ERROR = 'MODEL_STATUS_HISTORY_ERROR';

// Model - Versions
export const VERSIONS_LOADING = 'VERSIONS_LOADING';

export const VERSION_CREATE_LOADING = 'VERSION_CREATE_LOADING';
export const VERSION_CREATE_SUCCESS = 'VERSION_CREATE_SUCCESS';
export const VERSION_CREATE_ERROR = 'VERSION_CREATE_ERROR';

export const ALL_MODEL_VERSIONS_GET = 'ALL_MODEL_VERSIONS_GET';
export const ALL_MODEL_VERSIONS_ERROR = 'ALL_MODEL_VERSIONS_ERROR';

export const VERSION_UPDATE_ERROR = 'VERSION_UPDATE_ERROR';

export const VERSION_STATUS_HISTORY_GET = 'VERSION_STATUS_HISTORY_GET';
export const VERSION_STATUS_HISTORY_ERROR = 'VERSION_STATUS_HISTORY_ERROR';

// Services
export const ALL_SERVICES_GET = 'ALL_SERVICES_GET';
export const ALL_SERVICES_ERROR = 'ALL_SERVICES_ERROR';

export const SERVICE_INFO_GET = 'SERVICE_INFO_GET';
export const SERVICE_INFO_ERROR = 'SERVICE_INFO_ERROR';

export const SERVICE_INSTANCES_LOADING = 'SERVICE_INSTANCES_LOADING';
export const SERVICE_INSTANCES_GET = 'SERVICE_INSTANCES_GET';
export const SERVICE_INSTANCES_ERROR = 'SERVICE_INSTANCES_ERROR';

// Service Instance
export const SERVICE_INSTANCE_LOADING = 'SERVICE_INSTANCE_LOADING';

export const SERVICE_INSTANCE_INFO_GET = 'SERVICE_INSTANCE_INFO_GET';
export const SERVICE_INSTANCE_INFO_ERROR = 'SERVICE_INSTANCE_INFO_ERROR';

// Requests
export const REQUESTS_LOADING = 'REQUESTS_LOADING';

export const ALL_REQUESTS_GET = 'ALL_REQUESTS_GET';
export const ALL_REQUESTS_ERROR = 'ALL_REQUESTS_ERROR';

export const REQUEST_INFO_LOADING = 'REQUEST_INFO_LOADING';
export const REQUEST_INFO_GET = 'REQUEST_INFO_GET';
export const REQUEST_INFO_ERROR = 'REQUEST_INFO_ERROR';

export const REQUEST_LOGS_LOADING = 'REQUEST_LOGS_LOADING';
export const REQUEST_LOGS_GET = 'REQUEST_LOGS_GET';
export const REQUEST_LOGS_ERROR = 'REQUEST_LOGS_ERROR';

// Archive
export const ARCHIVE_LOADING = 'ARCHIVE_LOADING';

export const ALL_ARCHIVES_GET = 'ALL_ARCHIVES_GET';
export const ALL_ARCHIVES_ERROR = 'ALL_ARCHIVES_ERROR';

export const ARCHIVE_INFO_GET = 'ARCHIVE_INFO_GET';
export const ARCHIVE_INFO_ERROR = 'ARCHIVE_INFO_ERROR';

export const ARCHIVE_CREATE_LOADING = 'ARCHIVE_CREATE_LOADING';
export const ARCHIVE_CREATE_SUCCESS = 'ARCHIVE_CREATE_SUCCESS';
export const ARCHIVE_CREATE_ERROR = 'ARCHIVE_CREATE_ERROR';

export const SHOW_ARCHIVE_REFERENCE = 'SHOW_ARCHIVE_REFERENCE';

export const ARCHIVES_SEARCH_LOADING = 'ARCHIVES_SEARCH_LOADING';
export const ARCHIVES_SEARCH_GET = 'ARCHIVES_SEARCH_GET';
export const ARCHIVES_SEARCH_ERROR = 'ARCHIVES_SEARCH_ERROR';
