import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Card, Col, Container, Row, Table } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import StatusBadge from '../../../../utils/StatusBadge';
import LogsTerminal from '../../../logs/LogsTerminal';

// Actions
import { get_request_info, get_request_logs } from '../../../../../actions/requestsActions';

// Utils
import { getHandlerOutputDesc, getJobsStatusDesc, getRequestMethodDesc, getRequestServiceErrorDesc } from '../../../../../utils/getStatusDesc';
import { epochToLocalDate } from '../../../../../utils/dateConverter';
import isEmpty from '../../../../../utils/isEmpty';

const Details = () => {
	const { request_id } = useParams();

	const dispatch = useDispatch();

	const { request_info_loading, request_info, request_logs } = useSelector(state => state.requests);

	const [queryParamsList, setQueryParamsList] = useState([]);
	const [requestResultData, setRequestResultData] = useState(null);
	const [requestBody, setRequestBody] = useState(null);
	const [requestErrorDesc, setRequestErrorDesc] = useState(null);

	useEffect(() => {
		dispatch(get_request_info(request_id));
		dispatch(get_request_logs(request_id));
	}, []);

	useEffect(() => {
		if (!isEmpty(request_info)) {
			const query = request_info?.path?.query;
			if (query) {
				const paramsList = createQueryParamsList(query);
				setQueryParamsList(paramsList);
			}

			if (request_info?.result?.data) {
				if (typeof request_info?.result?.data === 'string') {
					const JSONData = JSON.parse(request_info?.result?.data);
					setRequestResultData(JSONData);
				}
				else {
					setRequestResultData(request_info?.result?.data);
				}
			}

			if (request_info?.body) {
				setRequestBody(request_info?.body);
			}

			if (request_info?.errors?.description) {
				setRequestErrorDesc(request_info?.errors?.description);
			}
		}
	}, [request_info]);

	const createQueryParamsList = (query) => {
		const params = query.split('&');
		let paramsList = [];

		params.forEach(param => {
			const [key, value] = param.split('=');
			paramsList.push({ key, value });
		});

		return paramsList;
	}

	if (request_info_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading request info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container'>
				<h2 className='text-center'>Request Details</h2>
				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Card.Title className='text-center' as='h5'>Main Information</Card.Title>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {request_info?._id?.$oid}</Card.Subtitle>
						<hr/>
						<Row>
							<Col className='text-center'><strong>Service:</strong> {request_info?.service?.$oid}</Col>
							<Col className='text-center'><strong>Instance:</strong> {request_info?.instance?.$oid}</Col>
						</Row>
						<hr/>
						<Row>
							<Col className='text-center'>
								<strong>Date:</strong> {epochToLocalDate(request_info?.date?.$date)}
							</Col>
							<Col className='text-center'>
								<strong>Start:</strong> {epochToLocalDate(request_info?.start * 1000)}
							</Col>
							<Col className='text-center'>
								<strong>End:</strong> {epochToLocalDate(request_info?.end * 1000)}
							</Col>
						</Row>
						<hr/>
						<Row>
							<Col className='text-center'><strong>Process:</strong> {request_info?.process}</Col>
							<Col className='text-center'><strong>Status:</strong> <StatusBadge status={getJobsStatusDesc(request_info?.status)} /></Col>
						</Row>
					</Card.Body>
				</Card>

				<hr className='mt-4'/>
				<h3 className='text-center pt-3 mb-3'>Path</h3>
				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Row>
							<Col className='text-center'><strong>Method:</strong> {getRequestMethodDesc(request_info?.path?.method)}</Col>
							<Col className='text-center'><strong>URL:</strong> {request_info?.path?.url}</Col>
						</Row>
						{queryParamsList.length > 0 &&
							<Table className='mt-4' responsive>
								<thead style={{ backgroundColor: '#e7f1ff' }}>
									<tr>
										<th>Key</th>
										<th>Value</th>
									</tr>
								</thead>
								<tbody>
									{queryParamsList.map((param, index) => (
										param.key !== '' && (
											<tr key={index}>
												<td>{param.key}</td>
												<td>{param.value}</td>
											</tr>
										)
									))}
								</tbody>
							</Table>
						}
						
					</Card.Body>
				</Card>

				<hr className='mt-4'/>
				<h3 className='text-center pt-3 mb-3'>Auth</h3>
				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Row>
							<Col className='text-center'><strong>Organization:</strong> {request_info?.auth?.organization ? request_info?.auth?.organization?.name : 'None'}</Col>
							<Col className='text-center'><strong>Source:</strong> {request_info?.auth?.source ? request_info?.auth?.source?.$oid : 'None'}</Col>
						</Row>
						<hr/>
						<Row>
							<Col className='text-center'><strong>User:</strong> {request_info?.auth?.user ? request_info?.auth?.user?.name : 'None'}</Col>
							<Col className='text-center'><strong>Username:</strong> {request_info?.auth?.user ? request_info?.auth?.user?.username : 'None'}</Col>
						</Row>
					</Card.Body>
				</Card>

				<hr className='mt-4'/>
				<h3 className='text-center pt-3 mb-3'>Token</h3>
				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Row>
							<Col className='text-center'><strong>Count:</strong> {request_info?.auth?.token?.count}</Col>
						</Row>
					</Card.Body>
				</Card>

				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Card.Title className='text-center' as='h5'>Data</Card.Title>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {request_info?.auth?.token?.data?._id?.$oid}</Card.Subtitle>
						<hr/>
						<Row>
							<Col className='text-center'><strong>Token Type:</strong> {request_info?.auth?.token?.data?.t_type}</Col>
							<Col className='text-center'><strong>Status:</strong> {request_info?.auth?.token?.data?.status}</Col>
						</Row>
						<hr/>
						<Row>
							<Col className='text-center'>
								<strong>Issued At (iat):</strong> {epochToLocalDate(request_info?.auth?.token?.data?.iat * 1000)}
							</Col>
							<Col className='text-center'>
								<strong>Expiration:</strong> {epochToLocalDate(request_info?.auth?.token?.data?.expiration * 1000)}
							</Col>
						</Row>
						<hr/>
						<Row>
							<Col>
								<p className='text-center m-0 p-0'><strong>Created:</strong> {epochToLocalDate(request_info?.auth?.token?.data?.created?.$date)}</p>
								<p className='text-center m-0 p-0'><strong>Expired:</strong> {epochToLocalDate(request_info?.auth?.token?.data?.expired?.$date, 'Expired')}</p>
							</Col>
							<Col>
								<p className='text-center m-0 p-0'><strong>Disabled:</strong> {epochToLocalDate(request_info?.auth?.token?.data?.disabled?.$date)}</p>
								<p className='text-center m-0 p-0'><strong>Revoked:</strong> {epochToLocalDate(request_info?.auth?.token?.data?.revoked?.$date)}</p>
							</Col>
						</Row>
					</Card.Body>
				</Card>

				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Card.Title className='text-center' as='h5'>Permissions</Card.Title>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {request_info?.auth?.token?.permissions?._id?.$oid}</Card.Subtitle>
						<hr/>
						<Row>
							<Col className='text-center'><strong>Permissions Type:</strong> {request_info?.auth?.token?.permissions?.permissions_type}</Col>
							<Col className='text-center'><strong>Status:</strong> {request_info?.auth?.token?.permissions?.scope}</Col>
						</Row>
					</Card.Body>
				</Card>

				<hr className='mt-4'/>
				<h3 className='text-center pt-3 mb-3'>Data</h3>
				{request_info?.data
					?	<>
						</>
					:	<p className='text-center'>No Data Sent</p>
				}

				<hr className='mt-4'/>
				<h3 className='text-center pt-3 mb-3'>Body</h3>
				{request_info?.body
					?	<Container className='terminal-container mt-3' style={{ height: 'auto' }}>
							<Container className='wrapper-container px-0'>
								<Col className='title mt-2'>
									<h5 style={{ textAlign: 'center', color: '#a2a2a2' }}><pre>percepthor@linux</pre></h5>
								</Col>
								<Col className='icons-btns mt-2 px-0'>
									<Container className='d-flex align-items-center justify-content-end'>
										<i className='bi bi-circle-fill mx-1' style={{ color: '#efcf84' }}></i>
										<i className='bi bi-circle-fill mx-1' style={{ color: '#94c68b' }}></i>
										<i className='bi bi-circle-fill mx-1' style={{ color: '#cf5062' }}></i>
									</Container>
								</Col>
							</Container>
							<Container className='terminal terminal-scrollbar pb-4'>
								<pre style={{ color: '#eeeeee', width: 'fit-content' }}>
									{JSON.stringify(requestBody, null, 2)}
								</pre>
							</Container>
						</Container>
					:	<p className='text-center'>No Body Sent</p>
				}

				{request_info?.result &&
					<div>
						<hr className='mt-4'/>
						<h3 className='text-center mb-3'>Response</h3>
						<Card className='shadow mb-5 bg-body rounded card-border'>
							<Card.Body>
								<Row>
									<Col className='text-center'><strong>Output:</strong> {getHandlerOutputDesc(request_info?.result?.output)}</Col>
								</Row>
							</Card.Body>
						</Card>

						{/* HANDLER_OUTPUT_TEXT */}
						{request_info?.result?.output === 1 &&
							<>
							</>
						}

						{/* HANDLER_OUTPUT_JSON */}
						{request_info?.result?.output === 2 &&
							<Container className='terminal-container mt-3' style={{ height: 'auto' }}>
								<Container className='wrapper-container px-0'>
									<Col className='title mt-2'>
										<h5 style={{ textAlign: 'center', color: '#a2a2a2' }}><pre>percepthor@linux</pre></h5>
									</Col>
									<Col className='icons-btns mt-2 px-0'>
										<Container className='d-flex align-items-center justify-content-end'>
											<i className='bi bi-circle-fill mx-1' style={{ color: '#efcf84' }}></i>
											<i className='bi bi-circle-fill mx-1' style={{ color: '#94c68b' }}></i>
											<i className='bi bi-circle-fill mx-1' style={{ color: '#cf5062' }}></i>
										</Container>
									</Col>
								</Container>
								<Container className='terminal terminal-scrollbar pb-4'>
									<pre style={{ color: '#eeeeee', width: 'fit-content' }}>
										{JSON.stringify(requestResultData, null, 2)}
									</pre>
								</Container>
							</Container>
						}

						{/* HANDLER_OUTPUT_FILE */}
						{request_info?.result?.output === 3 &&
							<>
								<p className='fw-bold'>Filename:</p>
								<p>{request_info?.result?.filename}</p>
							</>
						}

						{/* HANDLER_OUTPUT_OTHER */}
						{request_info?.result?.output === 4 &&
							<>
							</>
						}
					</div>
				}

				{request_info?.errors?.status !== 0 &&
					<div>
						<hr className='mt-4'/>
						<h3 className='text-center mb-3'>Errors</h3>
						<Card className='shadow mb-5 bg-body rounded card-border'>
							<Card.Body>
								<Row>
									<Col className='text-center'><strong>Status:</strong> {getRequestServiceErrorDesc(request_info?.errors?.status)}</Col>
								</Row>
							</Card.Body>
						</Card>
						
						{requestErrorDesc &&
							<Container className='terminal-container mt-3' style={{ height: 'auto' }}>
								<Container className='wrapper-container px-0'>
									<Col className='title mt-2'>
										<h5 style={{ textAlign: 'center', color: '#a2a2a2' }}><pre>percepthor@linux</pre></h5>
									</Col>
									<Col className='icons-btns mt-2 px-0'>
										<Container className='d-flex align-items-center justify-content-end'>
											<i className='bi bi-circle-fill mx-1' style={{ color: '#efcf84' }}></i>
											<i className='bi bi-circle-fill mx-1' style={{ color: '#94c68b' }}></i>
											<i className='bi bi-circle-fill mx-1' style={{ color: '#cf5062' }}></i>
										</Container>
									</Col>
								</Container>
								<Container className='terminal terminal-scrollbar pb-4'>
									<pre style={{ color: '#eeeeee', width: 'fit-content' }}>
										{JSON.stringify(requestErrorDesc, null, 2)}
									</pre>
								</Container>
							</Container>
						}
					</div>
				}

				<div>
					<hr className='mt-4'/>
					<h3 className='text-center mb-3'>Logs</h3>
					<LogsTerminal logs={request_logs} />
				</div>
			</Container>
		);
	}
}

export default Details;
