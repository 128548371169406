import Axios from 'axios';

import {
	MODELS_LOADING,
	MODEL_CREATE_LOADING,
	MODEL_CREATE_SUCCESS,
	MODEL_CREATE_ERROR,
	ALL_MODELS_GET,
	ALL_MODELS_ERROR,
	MODEL_INFO_GET,
	MODEL_INFO_ERROR,
	MODEL_UPDATE_ERROR,
	MODEL_STATUS_HISTORY_LOADING,
	MODEL_STATUS_HISTORY_GET,
	MODEL_STATUS_HISTORY_ERROR,
	VERSIONS_LOADING,
	VERSION_CREATE_LOADING,
	VERSION_CREATE_SUCCESS,
	VERSION_CREATE_ERROR,
	ALL_MODEL_VERSIONS_GET,
	ALL_MODEL_VERSIONS_ERROR,
	VERSION_UPDATE_ERROR,
	VERSION_STATUS_HISTORY_GET,
	VERSION_STATUS_HISTORY_ERROR
} from './types';

import { alert_remove, alert_set } from './alertActions';
import create_query_params from '../utils/create_query_params';

export const models_loading_true = () => dispatch => {
	dispatch({
		type: MODELS_LOADING,
		payload: true
	})
}

export const models_loading_false = () => dispatch => {
	dispatch({
		type: MODELS_LOADING,
		payload: false
	})
}

export const model_create = (modelBody, closeModalRef, filters) => dispatch => {
	dispatch({
		type: MODEL_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recon/models/create';

	Axios.post(url, modelBody)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODEL_CREATE_SUCCESS,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
			dispatch(get_all_models(filters));
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: MODEL_CREATE_SUCCESS,
				payload: false
			})
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODEL_CREATE_ERROR,
			payload: { type: 'model_create', msg: err.response.data?.error }
		});
	})
}

export const get_all_models = () => dispatch => {
	dispatch(models_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + '/api/recon/models?limit=0';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_MODELS_GET,
			payload: res.data
		});

		dispatch(models_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_MODELS_ERROR,
			payload: { type: 'all_models', msg: err.message }
		});

		dispatch(models_loading_false());
	})
}

export const get_model_info = (modelId) => dispatch => {
	dispatch(models_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/models/${modelId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODEL_INFO_GET,
			payload: res.data
		});

		dispatch(models_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODEL_INFO_ERROR,
			payload: { type: 'model_info', msg: err.message }
		});

		dispatch(models_loading_false());
	})
}

export const model_update = (modelId, modelBody, closeModalRef, filters, single) => dispatch => {
	dispatch({
		type: MODEL_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/models/${modelId}/update`;

	Axios.put(url, modelBody)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODEL_CREATE_SUCCESS,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
			if (single) {
				dispatch(get_model_info(modelId));
			}
			else{
				dispatch(get_all_models(filters));
			}
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: MODEL_CREATE_SUCCESS,
				payload: false
			})
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODEL_UPDATE_ERROR,
			payload: { type: 'model_update', msg: err.response.data?.error }
		});
	})
}

export const get_model_status_history = (modelId) => dispatch => {
	dispatch({
		type: MODEL_STATUS_HISTORY_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/models/${modelId}/status/history`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODEL_STATUS_HISTORY_GET,
			payload: res.data
		});

		dispatch({
			type: MODEL_STATUS_HISTORY_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODEL_STATUS_HISTORY_ERROR,
			payload: { type: 'model_status_history', msg: err.message }
		});

		dispatch({
			type: MODEL_STATUS_HISTORY_LOADING,
			payload: false
		});
	})
}

export const model_status_update = (modelId, status) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/models/${modelId}/status/update`;

	let statusBody = { status }

	Axios.put(url, statusBody)
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#198754', 'success', 'Model status updated successfully'));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		dispatch(get_all_models());
		dispatch(get_model_info(modelId));
	}).catch((err) => {
		// console.log(err);
		dispatch(alert_set('#dc3545', 'danger', 'Error updating model status'));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	})
}

export const model_delete = (modelId, filters, single) => dispatch => {
	dispatch(models_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/models/${modelId}/remove`;

	Axios.delete(url)
	.then((res) => {
		// console.log(res.data)
		if (single) {
			window.location.href = '/models';
		}
		else {
			dispatch(get_all_models(filters));
			dispatch(models_loading_false());
		}
	}).catch((err) => {
		// console.log(err);
		const statusCode = err.response.status;
		const errMsg = err.response.data?.error || (statusCode === 500 ? 'Internal Server Error' : 'Unknown Error')

		dispatch(alert_set('#dc3545', 'danger', `Error removing version. ${statusCode} — ${errMsg}`));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
		
		dispatch(models_loading_false());
	})
}

export const version_create = (versionBody, closeModalRef, filters) => dispatch => {
	dispatch({
		type: VERSION_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recon/versions/create';

	Axios.post(url, versionBody)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: VERSION_CREATE_SUCCESS,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
			dispatch(get_all_model_versions(filters));
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: VERSION_CREATE_SUCCESS,
				payload: false
			})
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: VERSION_CREATE_ERROR,
			payload: { type: 'version_create', msg: err.response.data?.error }
		});
	})
}

export const get_all_model_versions = (filters) => dispatch => {
	dispatch({
		type: VERSIONS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recon/versions?limit=0&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_MODEL_VERSIONS_GET,
			payload: res.data
		});

		dispatch({
			type: VERSIONS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_MODEL_VERSIONS_ERROR,
			payload: { type: 'all_model_versions', msg: err.message }
		});

		dispatch({
			type: VERSIONS_LOADING,
			payload: false
		});
	})
}

export const version_update = (versionId, versionBody, closeModalRef, filters) => dispatch => {
	dispatch({
		type: VERSION_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/versions/${versionId}/update`;

	Axios.put(url, versionBody)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: VERSION_CREATE_SUCCESS,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
			dispatch(get_all_model_versions(filters));
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: VERSION_CREATE_SUCCESS,
				payload: false
			})
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: VERSION_UPDATE_ERROR,
			payload: { type: 'version_update', msg: err.response.data?.error }
		});
	})
}

export const get_version_status_history = (versionId) => dispatch => {
	dispatch({
		type: MODEL_STATUS_HISTORY_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/versions/${versionId}/status/history`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: VERSION_STATUS_HISTORY_GET,
			payload: res.data
		});

		dispatch({
			type: MODEL_STATUS_HISTORY_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: VERSION_STATUS_HISTORY_ERROR,
			payload: { type: 'version_status_history', msg: err.message }
		});

		dispatch({
			type: MODEL_STATUS_HISTORY_LOADING,
			payload: false
		});
	})
}

export const version_status_update = (versionId, status, modelId) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/versions/${versionId}/status/update`;

	let statusBody = { status }

	Axios.put(url, statusBody)
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#198754', 'success', 'Version status updated successfully'));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		dispatch(get_all_model_versions({ model: modelId }));
	}).catch((err) => {
		// console.log(err);
		dispatch(alert_set('#dc3545', 'danger', 'Error updating version status'));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	})
}

export const version_delete = (versionId, filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/recon/versions/${versionId}/remove`;

	Axios.delete(url)
	.then((res) => {
		// console.log(res.data)
		dispatch(get_all_model_versions(filters));
	}).catch((err) => {
		// console.log(err);
		const statusCode = err.response.status;
		const errMsg = err.response.data?.error || (statusCode === 500 ? 'Internal Server Error' : 'Unknown Error')

		dispatch(alert_set('#dc3545', 'danger', `Error removing version. ${statusCode} — ${errMsg}`));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	})
}
