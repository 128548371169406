import {
	REQUESTS_LOADING,
	ALL_REQUESTS_GET,
	ALL_REQUESTS_ERROR,
	REQUEST_INFO_LOADING,
	REQUEST_INFO_GET,
	REQUEST_INFO_ERROR,
	REQUEST_LOGS_LOADING,
	REQUEST_LOGS_GET,
	REQUEST_LOGS_ERROR
} from '../actions/types';

const initialState = {
	requests_loading: false,
	all_requests: { count: 0, requests: [] },
	request_info_loading: false,
	request_info: {},
	requests_logs_loading: false,
	request_logs: [],
	requests_errors: {}
}

export default function requestsReducer(state = initialState, action){
	switch(action.type){
		case REQUESTS_LOADING:
			return {
				...state,
				requests_loading: action.payload
			}
		case ALL_REQUESTS_GET:
			return {
				...state,
				all_requests: {
					count: action.payload.count,
					requests: action.payload.requests
				}
			}
		case REQUEST_INFO_LOADING:
			return {
				...state,
				request_info_loading: action.payload
			}
		case REQUEST_INFO_GET:
			delete state.requests_errors.request_info;
			return {
				...state,
				request_info: action.payload
			}
		case REQUEST_LOGS_LOADING:
			return {
				...state,
				requests_logs_loading: action.payload
			}
		case REQUEST_LOGS_GET:
			delete state.requests_errors.request_logs;
			return {
				...state,
				request_logs: action.payload
			}
		case ALL_REQUESTS_ERROR:
		case REQUEST_INFO_ERROR:
		case REQUEST_LOGS_ERROR:
			return {
				...state,
				requests_errors: {
					...state.requests_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
